export default {
  methods: {
    formatHead(seomatic) {
      if (!seomatic) return {};

      // Convert the graphql JSON data to an object so we can work with it
      // Based on https://github.com/ben-rogerson/nuxt-seomatic-meta
      const {
        metaTitleContainer: {
          title: { title },
        },
        metaTagContainer,
        metaLinkContainer,
        metaScriptContainer,
        metaJsonLdContainer,
      } = Object.entries(seomatic).reduce((acc, [key, value]) => {
        if (key !== '__typename') acc[key] = JSON.parse(value);
        return acc;
      }, {});

      // Flatten metaTagContainer values into string
      const meta = metaTagContainer
        ? Object.values(metaTagContainer).reduce((flat, next) => {
            if (next.name === 'description') {
              // Override description tag with updated description
              next.hid = 'description';
            }
            return flat.concat(next);
          }, [])
        : null;

      // Flatten metaLinkContainer values into string
      const metaLinks = metaLinkContainer
        ? Object.values(metaLinkContainer).reduce(
            (flat, next) => flat.concat(next),
            [],
          )
        : null;

      let siteHandle = 'vig';

      if (metaLinks[0].href.indexOf(process.env.YIM_SITE_HOST) > 0) {
        siteHandle = 'yim';
      } else if (metaLinks[0].href.indexOf(process.env.MVM_SITE_HOST) > 0) {
        siteHandle = 'mvm';
      }

      const favicons = [
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `/${siteHandle}/apple-touch-icon.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `/${siteHandle}/favicon-32x32.png`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `/${siteHandle}/favicon-16x16.png`,
        },
        { rel: 'manifest', href: `/${siteHandle}/site.webmanifest` },
        {
          rel: 'mask-icon',
          href: `/${siteHandle}/safari-pinned-tab.svg`,
          color: siteHandle === 'yim' ? '#0752C7' : '#ff6b33',
        },
      ];

      const link = Object.assign(metaLinks, favicons);

      // Convert script data to script tag contents
      const metaScripts = metaScriptContainer
        ? Object.values(metaScriptContainer).map(({ script }) => ({
            innerHTML: script,
          }))
        : [];

      // Convert JsonLd to script tag contents
      const jsonLd = metaJsonLdContainer
        ? Object.entries(metaJsonLdContainer).map((value) => ({
            type: 'application/ld+json',
            innerHTML: JSON.stringify(value[1]),
          }))
        : [];

      // Combine processed script data
      const script = [...metaScripts, ...jsonLd];

      return {
        htmlAttrs: {
          lang: 'nl',
        },

        ...(title && { title }),
        ...(meta && { meta }),
        ...(link && { link }),
        script,
        __dangerouslyDisableSanitizers: ['script'],
      };
    },
  },
};
