
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    modules: VueTypes.array,
  },
  methods: {
    getComponent(type) {
      try {
        return {
          about: 'About',
          articlesOverview: 'ArticlesOverview',
          buttons: 'Buttons',
          campaignStories: 'CampaignStories',
          columns: 'Columns',
          contact: 'Contact',
          cookieConsent: 'CookieConsentModule',
          divider: 'Divider',
          download: 'Download',
          eventsOverview: 'EventsOverview',
          functionProfilesOverview: 'FunctionProfilesOverview',
          imageSlider: 'ImageSlider',
          imageWithText: 'ImageWithText',
          informationBanner: 'InformationBanner',
          media: 'Documents',
          membersOverview: 'MembersOverview',
          newsletterSubscribe: 'NewsletterSubscribe',
          newsOverview: 'NewsOverview',
          newsRelated: 'NewsRelated',
          patientStories: 'PatientStories',
          patientStoriesOverview: 'ArticlesOverview',
          publication: 'Publication',
          publicationsFeaturedOverview: 'PublicationsFeatured',
          publicationsOverview: 'PublicationsOverview',
          quote: 'Quote',
          representativesOverview: 'RepresentativesOverview',
          richText: 'RichTextModule',
          themes: 'Themes',
          themesOverview: 'ThemesOverview',
          tiles: 'Tiles',
          vacanciesOverview: 'VacanciesOverview',
          visual: 'VisualModule',
          ytEmbed: 'YTEmbed',
        }[type];
      } catch (e) {
        console.error('Missing module', type);
        return null;
      }
    },
  },
};
